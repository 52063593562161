import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// components
import AuthLayout from "./AuthLayout";

import mailSent from "../../assets/images/mail_sent.png";
import { RootState } from "../../redux/store";

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer footer-alt">
      <p className="text-muted">
        {t("2018 - " + new Date().getFullYear() + " © UBold theme by")}{" "}
        <Link to="#" className="text-muted">
          {t("Coderthemes")}
        </Link>
      </p>
    </footer>
  );
};

const UpdatePassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  // Extract the token and email from the URL
  const queryParams = new URLSearchParams(window.location.search);
//   const token = queryParams.get("token");
  const emailFromUrl = queryParams.get("email");
    console.log(emailFromUrl,token);
  useEffect(() => {
    // Set the email state from the URL
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    }
  }, [emailFromUrl]);

  const handlePasswordChange = (e:any) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmationChange = (e:any) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleUpdatePassword = async () => {
    try {
      const response = await axios.post("/reset-password", {
        email,
        password,
        password_confirmation: passwordConfirmation,
        token,
      });

      if (response.data.status) {
        setSuccess(t("Password updated successfully!"));
        // Optionally navigate to login page
        setTimeout(() => {
          navigate("/auth/login2");
        }, 2000);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError(t("An error occurred, please try again."));
    }
  };

  return (
    <>
      <AuthLayout bottomLinks={<BottomLink />}>
        <div className="text-center">
          <img src={mailSent} alt="mail sent" height="64" />
          <h3>{t("Reset Your Password")}</h3>
          <p className="text-muted font-14 mt-2">
            {t("Please enter your new password below.")}
          </p>

          {/* Email Input */}
          <div className="mt-3">
            <input
              type="email"
              className="form-control"
              placeholder={t("Email")}
              value={email}
              readOnly
            />
          </div>

          {/* Password Input */}
          <div className="mt-3">
            <input
              type="password"
              className="form-control"
              placeholder={t("New Password")}
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          {/* Password Confirmation Input */}
          <div className="mt-3">
            <input
              type="password"
              className="form-control"
              placeholder={t("Confirm New Password")}
              value={passwordConfirmation}
              onChange={handlePasswordConfirmationChange}
            />
          </div>

          {/* Error Message */}
          {error && <p className="text-danger mt-2">{error}</p>}
          {/* Success Message */}
          {success && <p className="text-success mt-2">{success}</p>}

          {/* Update Password Button */}
          <button
            className="btn w-100 btn-primary waves-effect waves-light mt-3"
            onClick={handleUpdatePassword}
          >
            {t("Update Password")}
          </button>

          <Link
            to="/auth/login2"
            className="btn w-100 btn-secondary waves-effect waves-light mt-3"
          >
            {t("Back to Login")}
          </Link>
        </div>
      </AuthLayout>
    </>
  );
};

export default UpdatePassword;
